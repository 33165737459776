export default function toggleFn() {
    const buttons = document.querySelectorAll('[data-toggle]');

    for (const button of buttons) {
        if (button.classList.contains('button-play')) {
            button.closest('.media-video')?.addEventListener('click', () => {
                const id = button.dataset.toggle;
                const elementToToggle = document.querySelector(
                    `[data-toggle-element=${id}]`);

                elementToToggle.classList.toggle('toggled');
            });
        }
        else {
            button.addEventListener('click', () => {
                const id = button.dataset.toggle;
                const elementToToggle = document.querySelector(
                    `[data-toggle-element=${id}]`);

                Object.values(document.querySelectorAll(`[data-toggle=${id}]`)).
                    map(item => item.classList.toggle('active'));
                elementToToggle.classList.toggle('toggled');

                if (elementToToggle.classList.contains('video--box')) {
                    const iframe = document.querySelector('.video--box iframe');
                    const iframeSrc = iframe.src;

                    iframe.setAttribute('src', iframeSrc);
                }
            });
        }
    }
};
