import gsap from "gsap";
import Parallaxx from './functions/parallax';
import AOS from 'aos';

import isMobile from 'ismobilejs';
const is_mobile = isMobile().phone || window.innerWidth <= 600;

export const backgroundAnim = () => {
    const sections = document.querySelectorAll('[data-background]');

    Object.values(sections).map(section => {
        const observer = new IntersectionObserver(async e => {
            if (e[0].isIntersecting) {
                const bgValue = section.dataset.background;

                gsap.to('html', 1, {
                    backgroundColor: getComputedStyle(document.documentElement).getPropertyValue(`--${bgValue}`)
                })
            } else {
                gsap.to('html', 1, {
                    backgroundColor: 'white'
                })
            }

        }, {
            rootMargin: `-50%`
        });

        observer.observe(section);
    })
}

export const activitiesAnim = () => {
    Parallaxx('.activities--item:nth-child(odd)', 'div', .6);
}

export const loaderAnim = () => {
    const loaded = sessionStorage.getItem('loaded');
    const loader = document.querySelector('.loader');

    if (!loaded) {
        const logo = loader.querySelector('svg');
        sessionStorage.setItem('loaded', 'true');

        gsap.set(loader, {
            opacity: 1,
            delay: 0,
        });

        gsap.to(logo, .5, {
            opacity: 1, y: 0,
            onComplete: () => loader.classList.add('loaded')
        })
    } else {
        loader.remove();
    }
}

export const animateOnScroll = () => {
    const images = document.querySelectorAll('.article-body .wp-block-image');
    let index = 0;

    Object.values(images).map(image => {
        if (image.closest('figure.wp-block-gallery')) {
            image.setAttribute('data-aos-delay', index * 150 );
            index++;
        } else {
            index = 0;
        }

        image.setAttribute('data-aos-offset', '400');
        image.setAttribute('data-aos', 'fade-up')
    });

    AOS.init({
        once: true,
        startEvent: 'DOMContentLoaded',
        disable: () => is_mobile
    })
}


export const singleAnim = () => {
    const singleContent = document.querySelector('.single--content .media-image');

    if (singleContent) {
       Parallaxx('.single--content .media-image', 'div', 0.2)
    }
}
