import gsap from 'gsap';

window.addEventListener('DOMContentLoaded', () => {
    burgerMenu();
    subMenuToggle();
});

let lastScrollTop = 0;
addEventListener('scroll', () => {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    const header = document.querySelector('.main-header');

    header.classList.toggle('scrolled', window.scrollY > document.querySelector('.section--masthead')?.getBoundingClientRect().height);

    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
});

const burgerMenu = () => {
    const button = document.querySelector('.button--burger');
    const header = document.querySelector('.main-header');
    const items = header.querySelectorAll('li');

    button.addEventListener('click', () => {
        header.classList.toggle('toggled');
        button.classList.toggle('active');
    });
};

const subMenuToggle = () => {
    const buttons = document.querySelectorAll('[data-toggle-submenu]');

    Object.values(buttons).map(button => {
        const wrapper = button.closest('.navigation .menu-item--wrapper');
        if (wrapper) {

            const navChildren = wrapper.nextElementSibling;
            const items = navChildren.querySelectorAll('.menu-item a');

            //Initialization of the native height
            navChildren.dataset.height = navChildren.getBoundingClientRect().height;
            gsap.set(navChildren, {
                height: 0,
            });

            //Click event
            button.addEventListener('click', () => {
                const show = wrapper.classList.contains('toggled');

                //CLOSE OTHERS
                const toClose = document.querySelectorAll('.navigation .navigation--children.toggled');

                Object.values(toClose).map((element, index) => {
                    if (element.previousElementSibling !== wrapper) {

                        gsap.to(element, .3, {
                            height: 0,
                            onComplete: () => {
                                element.classList.remove('toggled');
                                element.previousElementSibling.classList.remove('toggled');
                            },
                        });
                    }
                });

                wrapper.classList.toggle('toggled');
                navChildren.classList.toggle('toggled');

                gsap.to(navChildren, .3, {
                    height: show ? 0 : parseFloat(navChildren.dataset.height),
                    onComplete: () => {

                    },
                });
            });
        }
    });
};
