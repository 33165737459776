import gsap from 'gsap';
import toggleFn from './functions/toggle';
import isMobile from 'ismobilejs';
import Swiper from 'swiper';
import Navigation from 'swiper/modules/navigation.mjs';
import Parallax from 'swiper/modules/parallax.mjs';
import FreeMode from 'swiper/modules/free-mode.mjs';
import './functions/sizeSVG';
import {activitiesAnim, animateOnScroll, backgroundAnim, loaderAnim, singleAnim} from './animations';

import './header';

const is_mobile = isMobile().phone || window.innerWidth <= 600;

addEventListener('DOMContentLoaded', () => {
    toggleFn();
    initSlider();
    loadMore();

    if (!is_mobile) {
        cursorHandler();
    }

    //animations
    animateOnScroll();
    backgroundAnim();
    activitiesAnim();
    loaderAnim();
    singleAnim();

});

const initSlider = () => {
    const elements = document.querySelectorAll('[data-swiper]');

    Object.values(elements).map(el => {
        console.log(is_mobile);
        const slider = new Swiper(el, {
            modules: [Navigation, Parallax, FreeMode],
            slideClass: 'swiper-slide',
            speed: 1000,
            slidesPerView: 'auto',
            freeMode: is_mobile,
            parallax: true,
            spaceBetween: 10,
            autoHeight: !isMobile().phone,
            navigation: {
                nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
            },
        });

        slider.init();

        const cursor = document.querySelector('.cursor');
        const cursorText = document.querySelector('.cursor > span');
        const sliderEl = slider.el;

        sliderEl.addEventListener('mouseenter', () => {
            cursor.classList.add('cursor--light');
            cursorText.innerText = sliderEl.dataset.cursorText;
        });

        sliderEl.addEventListener('mouseleave', () => {
            cursor.classList.remove('cursor--light');
            cursorText.innerText = cursor.dataset.wording;
        });

        slider.on('touchMove', (e) => {
            cursorMove(cursor, e.touches.currentX, e.touches.currentY - window.scrollY);
        });

        const buttonDiscover = slider.wrapperEl.querySelectorAll('.button--link');

        Object.values(buttonDiscover).map(button => {
            button.addEventListener('mouseenter', () => {
                gsap.to(cursor, .3, {
                    opacity: 0,
                });
            });

            button.addEventListener('mouseleave', () => {
                gsap.to(cursor, .3, {
                    opacity: 1,
                });
            });
        });
    });

    if (isMobile().phone || window.innerWidth <= 600) {
        const sliderContents = document.querySelectorAll('.activities--content');
        let maxHeight = 0;

        Object.values(sliderContents).map(element => {
            const elHeight = element.getBoundingClientRect().height;
            if (elHeight > maxHeight) {
                maxHeight = elHeight;
            }
        });

        Object.values(sliderContents).map(element => {
            gsap.set(element, {
                height: maxHeight, backgroundColor: element.closest('.activities--item').dataset.color,
            });
        });
    }
};

const cursorMove = (cursor, x, y) => {
    gsap.to(cursor, .4, {
        y: y - cursor.getBoundingClientRect().width / 2, x: x - cursor.getBoundingClientRect().width / 2,
    });
};

const loadMore = () => {
    const button = document.querySelector('.button--load');

    if (button) {
        const observer = new IntersectionObserver(async e => {
            if (e[0].isIntersecting) {
                const listing = button.previousElementSibling;
                const type = button.previousElementSibling.dataset.type;
                const page = parseFloat(button.previousElementSibling.dataset.page);
                const lang = button.previousElementSibling.dataset.lang;

                const content = await fetch('/wp-json/load/v1/content', {
                    headers: {
                        'Accept': 'application/json', 'Content-Type': 'application/json',
                    }, method: 'post', body: JSON.stringify({
                        type: type, page: page, lang: lang,
                    }),
                }).then(res => res.text());

                if (content) {
                    const parser = new DOMParser();
                    const html = parser.parseFromString(content, 'text/html');
                    const articles = html.querySelector('body').children;

                    Object.values(articles).map(article => {
                        //HIDING
                        if (article.nodeName.toLowerCase() === 'article') {
                            article.classList.add('hidden');
                        }
                        else {
                            Object.values(article.querySelectorAll('article')).map(el => el.classList.add('hidden'));
                        }

                        listing.append(article);

                        //SHOWING
                        setTimeout(() => {
                            if (article.nodeName.toLowerCase() === 'article') {
                                article.classList.remove('hidden');
                            }
                            else {
                                Object.values(article.querySelectorAll('article')).map(el => el.classList.remove('hidden'));
                            }
                        }, 100);
                    });

                    button.previousElementSibling.dataset.page = parseInt(page) + 1;

                    cursorHandler();
                }
                else {
                    button.remove();
                }
            }

        }, {});

        observer.observe(button);
    }
};

const cursorHandler = () => {
    const cursor = document.querySelector('.cursor');
    const cursorText = document.querySelector('.cursor > span');

    if (cursor) {
        window.addEventListener('mousemove', (e) => {
            const rect = cursor.getBoundingClientRect();

            gsap.to(cursor, .5, {
                y: e.clientY,
                x: e.clientX,
            });
        });

        const cursorShow = document.querySelectorAll('.cursor--show');

        Object.values(cursorShow).map(el => {
            el.addEventListener('mouseenter', () => {
                if (el.classList.contains('cursor--show')) {
                    gsap.to(cursor, .4, {
                        scale: 1,
                    });

                    cursorText.innerText = el.dataset.cursorText ?? cursor.dataset.wording;

                    cursor.classList.add('cursor--hover');
                }
            });

            el.addEventListener('mouseleave', () => {
                gsap.to(cursor, .4, {
                    scale: 0,
                });

                cursorText.innerText = cursor.dataset.wording;
                cursor.classList.remove('cursor--hover');
            });
        });
    }
};
