import gsap from 'gsap';
import isMobile from 'ismobilejs';
const is_mobile =  isMobile().phone || window.innerWidth <= 600;

export default function Parallaxx(element, closest, offset) {
    const el = document.querySelectorAll(element);
    let lastScrollTop = 0;

    if (!is_mobile) {
        Object.values(el).map((item, index) => animation(item, closest, offset));
    }


    window.addEventListener('scroll', (e) => {
        const st = window.pageYOffset || document.documentElement.scrollTop;

        if (!is_mobile) Object.values(el).map(item => animation(item, closest, offset));

        if (st > lastScrollTop) {
            // downscroll code
            window.scrollDirection = 'down';
        }
        else {
            // upscroll code
            window.scrollDirection = 'up';
        }

        lastScrollTop = st <= 0 ? 0 : st;
    }, {passive: true});
}

const animation = (item, closest, offset) => {
    const parentSection = closest ? item.closest(closest) : item.closest('section');
    const parentRect = parentSection.getBoundingClientRect();

    gsap.set(item, {
        y: -(parentRect.y) * .2 * offset,
    });
};
